import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSearchAssets } from '../../../api/groups'
import SydAutocomplete from '../../commonDesign/SydAutocomplete'
import { useAppContext } from '../../../redux/slices/appContext'
import useDebouncedValue from '../../../hooks/useDebounceValue'

const useScopeFilter = (scope) => {
  const { clientId } = useAppContext()
  return useMemo(() => {
    if (scope === 'client') {
      return {
        clientId,
        holdingStatus: 'ever'
      }
    }
    return {}
  }, [scope, clientId])
}

function AssetPicker ({
  value,
  onChange,
  baseQuery,
  scope
}) {
  const [searchText, setSearchText] = useState('')
  const _debouncedText = useDebouncedValue(searchText, 200)
  const scopeFilter = useScopeFilter(scope)

  const optionQuery = useMemo(() => {
    return {
      ...(baseQuery || {}),
      filters: {
        ...(baseQuery?.filters || {}),
        ...(scopeFilter || {})
      },
      textSearch: _debouncedText ? ({
        assetIdentifier: [{ op: 'contains', value: _debouncedText }],
        assetName: [{ op: 'contains', value: _debouncedText }]
      }) : undefined,
      take: 100
    }
  }, [baseQuery, _debouncedText, scopeFilter])

  const valueQuery = useMemo(() => value ? ({
    filters: {
      assetId: value
    },
    take: 1
  }) : null, [value])

  const { data: optionsData, isLoading: optionsLoading } = useSearchAssets(optionQuery, { enabled: !!optionQuery })
  const { data: valueData, isFetching: valueLoading } = useSearchAssets(valueQuery, { enabled: !!valueQuery })

  const options = useMemo(() => {
    return (optionsData || []).map(asset => ({
      value: asset.assetId,
      label: asset.assetName,
      subLabel: asset.assetIdentifier
    }))
  }, [optionsData])

  const _value = useMemo(() => {
    if (!value) return null
    const asset = valueData?.length ? valueData.at(0) : null
    return asset ? ({
      value: asset.assetId,
      label: asset.assetName,
      subLabel: asset.assetIdentifier
    }) : null
  }, [valueData, value])

  return (
    <SydAutocomplete
      value={_value}
      onChange={(e, val) => onChange(val?.value || null)}
      onBlur={(e, val) => onChange(val?.value || null)}
      options={options}
      isLoading={optionsLoading || valueLoading}
      disabled={valueLoading}
      onInputChange={(e, val) => setSearchText(val)}
    />
  )
}

AssetPicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  baseQuery: PropTypes.object,
  scope: PropTypes.oneOf(['all', 'client'])
}

export default AssetPicker
