import React from 'react'
import PropTypes from 'prop-types'
import AssetPicker from '../../../../../AssetPicker'

function AssetSelector (props) {
  return (
    <AssetPicker {...props} scope='client' />
  )
}

AssetSelector.propTypes = {
  value: PropTypes.any
}

export default AssetSelector
