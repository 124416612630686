import React, { useCallback, useImperativeHandle, useMemo, useState } from 'react'
import RoundedModal from '../../molecules/RoundedModal'
import TearSheetDialogForm from './form'

function findSegment (levelTypeId) {
  switch (levelTypeId) {
    case 1:
      return 'a'
    case 3:
      return 's'
    default:
      return null
  }
}

const TearSheetDialog = React.forwardRef(function TearSheetDialog (_, ref) {
  const [dialogState, setDialogState] = useState({ open: false, processing: false })
  const close = useCallback(() => {
    setDialogState({ open: false, processing: false })
  }, [setDialogState])

  useImperativeHandle(ref, () => ({
    open: (config) => setDialogState({ config, open: true, processing: false })
  }), [setDialogState])

  const otherActions = useMemo(() => {
    const segment = findSegment(dialogState.config?.levelTypeId)
    if (!segment) {
      return []
    }
    return [{
      icon: 'openInNew',
      onClick: () => window.open(`/tear-sheets/${segment}/${dialogState.config.levelId}`, '_blank', 'noopener,noreferrer')
    }]
  }, [dialogState])

  return (
    <RoundedModal
      title={dialogState.config?.title ?? 'Tear Sheet'}
      open={dialogState.open}
      onClose={close}
      position='top'
      size='full'
      disablePortal={false}
      otherActions={otherActions}
    >
      <div>
        {dialogState.open ? (<TearSheetDialogForm config={dialogState.config} onComplete={close} onCancel={close} />) : null}
      </div>
    </RoundedModal>
  )
})

TearSheetDialog.propTypes = {
}

export default TearSheetDialog
