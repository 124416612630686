import React from 'react'
import PropTypes from 'prop-types'
import AccountPicker from '../../../../../AccountPicker'

function AccountSelector (props) {
  return (
    <AccountPicker {...props} scope='client' />
  )
}

AccountSelector.propTypes = {
  value: PropTypes.any
}

export default AccountSelector
