import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import { useSearchAssets } from '../../../../../../../api/groups'
import FlexRow from '../../../../../../molecules/FlexRow'
import FadeIn from '../../../../../../molecules/Transitions/FadeIn'
import Icon from '../../../../../../atoms/Icon'
import TearSheetDialog from '../../../../../TearSheetDialog'

const useStyles = makeStyles((theme) => ({
  assetDisplay: {
    '& .__asset-name': {
      fontSize: theme.typography.fontSizes.h4
    },
    '& .__asset-identifier': {
      marginTop: '-5px',
      fontSize: theme.typography.fontSizes.lg,
      fontStyle: 'italic',
      color: `color-mix(in srgb, ${theme.palette.text.primary} 75%, transparent)`
    }
  }
}))

function AssetDisplayField ({ field }) {
  const classes = useStyles()
  const tearSheetRef = useRef(null)
  const query = useMemo(() => {
    return field?.value ? {
      filters: {
        assetId: field?.value
      },
      limit: 1
    } : null
  }, [field])
  const { data, isFetching } = useSearchAssets(query, { enabled: !!query })
  const asset = useMemo(() => {
    return (data?.length ? data.at(0) : null)
  }, [data])

  const handleClick = useCallback(() => {
    if (!asset.tearSheetId) return

    tearSheetRef.current?.open({
      levelTypeId: 3,
      levelId: asset.assetId,
      title: asset.assetName,
      tearSheetId: asset.tearSheetId,
      bindings: {
        assetId: asset.assetId
      }
    })
  }, [tearSheetRef, asset])

  if (!query || isFetching || !asset) return null

  return (
    <FadeIn className={classes.assetDisplay}>
      <FlexRow>
        <div className={clsx('__asset-name', { clickable: asset?.tearSheetId })} onClick={handleClick}>{asset.assetName}</div>
        {asset.tearSheetId ? (
          <IconButton onClick={handleClick}>
            <Icon name='expand' />
          </IconButton>
        ) : null}
      </FlexRow>
      <div className={clsx('__asset-identifier', { clickable: asset?.tearSheetId })} onClick={handleClick}>{asset.assetIdentifier}</div>
      <TearSheetDialog ref={tearSheetRef} />
    </FadeIn>
  )
}

AssetDisplayField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any,
    data: PropTypes.shape({
      hideTearSheet: PropTypes.bool
    })
  })
}

export default AssetDisplayField
