import React, { useCallback, useMemo, useRef } from 'react'
import PropTypes from 'prop-types'
import { IconButton, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import FlexRow from '../../../../../../molecules/FlexRow'
import FadeIn from '../../../../../../molecules/Transitions/FadeIn'
import Icon from '../../../../../../atoms/Icon'
import TearSheetDialog from '../../../../../TearSheetDialog'
import { useSearchAccounts } from '../../../../../../../api/accounts'

const useStyles = makeStyles((theme) => ({
  accountDisplay: {
    '& .__account-name': {
      fontSize: theme.typography.fontSizes.h4
    },
    '& .__account-identifier': {
      marginTop: '-5px',
      fontSize: theme.typography.fontSizes.lg,
      fontStyle: 'italic',
      color: `color-mix(in srgb, ${theme.palette.text.primary} 75%, transparent)`
    }
  }
}))

function AccountDisplayField ({ field }) {
  const classes = useStyles()
  const tearSheetRef = useRef(null)
  const query = useMemo(() => {
    return field?.value ? {
      filters: {
        accountId: field?.value
      },
      limit: 1
    } : null
  }, [field])
  const { data, isFetching } = useSearchAccounts(query, { enabled: !!query })
  const account = useMemo(() => {
    return (data?.data?.length ? data.data.at(0) : null)
  }, [data])

  const handleClick = useCallback(() => {
    if (!account.tearSheetId) return

    tearSheetRef.current?.open({
      levelTypeId: 1,
      levelId: account.accountId,
      title: account.accountName,
      tearSheetId: account.tearSheetId,
      bindings: {
        accountId: account.accountId
      }
    })
  }, [tearSheetRef, account])

  if (!query || isFetching || !account) return null

  return (
    <FadeIn className={classes.accountDisplay}>
      <FlexRow>
        <div className={clsx('__account-name', { clickable: account?.tearSheetId })} onClick={handleClick}>{account.accountName}</div>
        {account.tearSheetId ? (
          <IconButton onClick={handleClick}>
            <Icon name='expand' />
          </IconButton>
        ) : null}
      </FlexRow>
      <div className={clsx('__account-identifier', { clickable: account?.tearSheetId })} onClick={handleClick}>{account.accountNumber}</div>
      <TearSheetDialog ref={tearSheetRef} />
    </FadeIn>
  )
}

AccountDisplayField.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.any,
    data: PropTypes.shape({
      hideTearSheet: PropTypes.bool
    })
  })
}

export default AccountDisplayField
