import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import SydAutocomplete from '../../commonDesign/SydAutocomplete'
import { useAppContext } from '../../../redux/slices/appContext'
import useDebouncedValue from '../../../hooks/useDebounceValue'
import { useSearchAccounts } from '../../../api/accounts'

const useScopeFilter = (scope) => {
  const { clientId } = useAppContext()
  return useMemo(() => {
    if (scope === 'client') {
      return {
        clientId
      }
    }
    return {}
  }, [scope, clientId])
}

function AccountPicker ({
  value,
  onChange,
  baseQuery,
  scope
}) {
  const [searchText, setSearchText] = useState('')
  const _debouncedText = useDebouncedValue(searchText, 200)
  const scopeFilter = useScopeFilter(scope)

  const optionQuery = useMemo(() => {
    return {
      ...(baseQuery || {}),
      filters: {
        ...(baseQuery?.filters || {}),
        ...(scopeFilter || {})
      },
      textSearch: _debouncedText ? ({
        accountName: [{ op: 'contains', value: _debouncedText }],
        accountNumber: [{ op: 'contains', value: _debouncedText }]
      }) : undefined,
      take: 100
    }
  }, [baseQuery, _debouncedText, scopeFilter])

  const valueQuery = useMemo(() => value ? ({
    filters: {
      accountId: value
    },
    take: 1
  }) : null, [value])

  const { data: optionsData, isLoading: optionsLoading } = useSearchAccounts(optionQuery, { enabled: !!optionQuery })
  const { data: valueData, isFetching: valueLoading } = useSearchAccounts(valueQuery, { enabled: !!valueQuery })

  const options = useMemo(() => {
    return (optionsData?.data || []).map(account => ({
      value: account.accountId,
      label: account.accountName,
      subLabel: account.accountNumber
    }))
  }, [optionsData])

  const _value = useMemo(() => {
    if (!value) return null
    const account = valueData?.data?.length ? valueData.data.at(0) : null
    return account ? ({
      value: account.accountId,
      label: account.accountName,
      subLabel: account.accountNumber
    }) : null
  }, [valueData, value])

  return (
    <SydAutocomplete
      value={_value}
      onChange={(e, val) => onChange(val?.value || null)}
      onBlur={(e, val) => onChange(val?.value || null)}
      options={options}
      isLoading={optionsLoading || valueLoading}
      disabled={valueLoading}
      onInputChange={(e, val) => setSearchText(val)}
    />
  )
}

AccountPicker.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  baseQuery: PropTypes.object,
  scope: PropTypes.oneOf(['all', 'client'])
}

export default AccountPicker
