import React from 'react'
import PropTypes from 'prop-types'
import { usePolicy } from '../../../../../../hooks/usePolicy'
import TitleFieldInput from './TitleFieldInput'
import PreformattedTextFieldInput from './PreformattedTextFieldInput'
import MarkdownFieldInput from './MarkdownFieldInput'
import DateFieldInput from './DateFieldInput'
import NumberFieldInput from './NumberFieldInput'
import LinkFieldInput from './LinkFieldInput'
import EmbeddedContentFieldInput from './EmbeddedContentFieldInput'
import AssetSelector from './Inputs/AssetSelector'
import AccountSelector from './Inputs/AccountSelector'

function EntryContentFieldInput ({ type, ...props }) {
  let component = null
  const isAdvisor = usePolicy('advisors')

  if (type === 'title') {
    component = <TitleFieldInput {...props} />
  }

  if (type === 'text') {
    component = <PreformattedTextFieldInput {...props} />
  }

  if (type === 'markdown') {
    component = <MarkdownFieldInput {...props} />
  }

  if (type === 'link') {
    component = <LinkFieldInput {...props} />
  }

  if (type === 'number') {
    component = <NumberFieldInput {...props} />
  }

  if (type === 'embedded') {
    component = <EmbeddedContentFieldInput {...props} />
  }

  if (type === 'date') {
    component = <DateFieldInput {...props} />
  }

  if (type === 'asset' && isAdvisor) {
    component = <AssetSelector {...props} />
  }

  if (type === 'account' && isAdvisor) {
    component = <AccountSelector {...props} />
  }

  return (
    <>
      {component}
    </>
  )
}

EntryContentFieldInput.propTypes = {
  type: PropTypes.string
}

export default EntryContentFieldInput
